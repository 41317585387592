import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { sanitizeEntity } from './sanitize';
import config from '../config';
import { types as sdkTypes } from './sdkLoader';
import { convertMoneyToNumber, convertUnitToSubUnit, formatMoney, unitDivisor } from './currency';
import {
  bookingTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_CUSTOMER_PROMO,
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_MASTERCARD_PROMO,
  LINE_ITEM_PROCESSING_FEE,
  LINE_ITEM_TRIP_PRICE_PEAK_DAYS,
  LINE_ITEM_TRIP_PRICE_PEAK_DAYS_REFUND,
  LINE_ITEM_TRIP_PRICE_PEAK_HOURS,
  LINE_ITEM_TRIP_PRICE_PEAK_HOURS_REFUND,
  LINE_ITEM_TRIP_PRICE_REGULAR_DAYS,
  LINE_ITEM_TRIP_PRICE_REGULAR_DAYS_REFUND,
  LINE_ITEM_TRIP_PRICE_REGULAR_HOURS,
  LINE_ITEM_TRIP_PRICE_REGULAR_HOURS_REFUND,
  LINE_ITEM_UNITS,
  LINE_ITEM_YOUNG_CUSTOMER_COMMISSION,
} from './types';
import Decimal from 'decimal.js';
import get from 'lodash/get';
import {
  txIsNormalCommercial,
  txIsNormalPrivate,
  txIsYoungCommercial,
  txIsYoungPrivate,
} from './transaction';
import merge from 'lodash/merge';

const { Money } = sdkTypes;

/**
 * Combine the given relationships objects
 *
 * See: http://jsonapi.org/format/#document-resource-object-relationships
 */
export const combinedRelationships = (oldRels, newRels) => {
  if (!oldRels && !newRels) {
    // Special case to avoid adding an empty relationships object when
    // none of the resource objects had any relationships.
    return null;
  }
  return { ...oldRels, ...newRels };
};

/**
 * Combine the given resource objects
 *
 * See: http://jsonapi.org/format/#document-resource-objects
 */
export const combinedResourceObjects = (oldRes, newRes) => {
  const { id, type } = oldRes;
  if (newRes.id.uuid !== id.uuid || newRes.type !== type) {
    throw new Error('Cannot merge resource objects with different ids or types');
  }
  const attributes = newRes.attributes || oldRes.attributes;
  const attrs = attributes ? { attributes: { ...attributes } } : null;
  const relationships = combinedRelationships(oldRes.relationships, newRes.relationships);
  const rels = relationships ? { relationships } : null;
  return { id, type, ...attrs, ...rels };
};

/**
 * Combine the resource objects form the given api response to the
 * existing entities.
 */
export const updatedEntities = (oldEntities, apiResponse) => {
  const { data, included = [] } = apiResponse;
  const objects = (Array.isArray(data) ? data : [data]).concat(included);

  const newEntities = objects.reduce((entities, curr) => {
    const { id, type } = curr;

    // Some entities (e.g. listing and user) might include extended data,
    // you should check if src/util/sanitize.js needs to be updated.
    const current = sanitizeEntity(curr);

    entities[type] = entities[type] || {};
    const entity = entities[type][id.uuid];
    if (
      (type === 'user' || type === 'currentUser') &&
      current &&
      current.attributes &&
      current.attributes.profile &&
      current.attributes.profile.displayName
    ) {
      current.attributes.profile.displayName = current.attributes.profile.displayName.includes(' ')
        ? current.attributes.profile.displayName
          .split(' ')
          .filter((word, index, array) => index < array.length - 1)
          .join(' ')
        : current.attributes.profile.displayName;
    }
    entities[type][id.uuid] = entity ? combinedResourceObjects({ ...entity }, current) : current;

    return entities;
  }, oldEntities);

  return newEntities;
};

/**
 * Denormalise the entities with the resources from the entities object
 *
 * This function calculates the dernormalised tree structure from the
 * normalised entities object with all the relationships joined in.
 *
 * @param {Object} entities entities object in the SDK Redux store
 * @param {Array<{ id, type }} resources array of objects
 * with id and type
 * @param {Boolean} throwIfNotFound wheather to skip a resource that
 * is not found (false), or to throw an Error (true)
 *
 * @return {Array} the given resource objects denormalised that were
 * found in the entities
 */
export const denormalisedEntities = (entities, resources, throwIfNotFound = true) => {
  const denormalised = resources.map(res => {
    const { id, type } = res;
    const entityFound = entities[type] && id && entities[type][id.uuid];
    if (!entityFound) {
      if (throwIfNotFound) {
        throw new Error(`Entity with type "${type}" and id "${id ? id.uuid : id}" not found`);
      }
      return null;
    }
    const entity = entities[type][id.uuid];
    const { relationships, ...entityData } = entity;

    if (relationships) {
      // Recursively join in all the relationship entities
      return reduce(
        relationships,
        (ent, relRef, relName) => {
          // A relationship reference can be either a single object or
          // an array of objects. We want to keep that form in the final
          // result.
          const hasMultipleRefs = Array.isArray(relRef.data);
          const multipleRefsEmpty = hasMultipleRefs && relRef.data.length === 0;
          if (!relRef.data || multipleRefsEmpty) {
            ent[relName] = hasMultipleRefs ? [] : null;
          } else {
            const refs = hasMultipleRefs ? relRef.data : [relRef.data];

            // If a relationship is not found, an Error should be thrown
            const rels = denormalisedEntities(entities, refs, true);

            ent[relName] = hasMultipleRefs ? rels : rels[0];
          }
          return ent;
        },
        entityData
      );
    }
    return entityData;
  });

  return denormalised.filter(e => !!e);
};

/**
 * Denormalise the data from the given SDK response
 *
 * @param {Object} sdkResponse response object from an SDK call
 *
 * @return {Array} entities in the response with relationships
 * denormalised from the included data
 */
export const denormalisedResponseEntities = sdkResponse => {
  const apiResponse = sdkResponse.data;
  const data = apiResponse.data;
  const resources = Array.isArray(data) ? data : [data];

  if (!data || resources.length === 0) {
    return [];
  }

  const entities = updatedEntities({}, apiResponse);
  return denormalisedEntities(entities, resources);
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} transaction entity object, which is to be ensured against null values
 */
export const ensureTransaction = (transaction, booking = null, listing = null, provider = null) => {
  const empty = {
    id: null,
    type: 'transaction',
    attributes: {
      protectedData: {},
      metaData: {},
    },
    booking,
    listing,
    provider,
  };
  return merge(empty, transaction);
};

export const ensureHostVerified = (hostUser) => {
  const getHostVerificationStatus = get(hostUser, 'attributes.profile.publicData.hostIdentityVerificationStatus', '')
  const isVerified = getHostVerificationStatus === 'confirmed';
  return isVerified;
};


/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} booking entity object, which is to be ensured against null values
 */
export const ensureBooking = booking => {
  const empty = { id: null, type: 'booking', attributes: {} };
  return { ...empty, ...booking };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureListing = listing => {
  const empty = {
    id: null,
    type: 'listing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureOwnListing = listing => {
  const empty = {
    id: null,
    type: 'ownListing',
    attributes: { publicData: {}, metadata: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} user entity object, which is to be ensured against null values
 */
export const ensureUser = user => {
  const empty = { id: null, type: 'user', attributes: { profile: {} } };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} current user entity object, which is to be ensured against null values
 */
export const ensureCurrentUser = user => {
  const empty = {
    id: null,
    type: 'currentUser',
    attributes: { profile: { metadata: { guestReferralCodeData: {} } } },
    profileImage: {},
    identityStatus: {
      hostVerified: false,
      hostUnderVerify: false,
      guestVerified: false,
      guestUnderVerify: false,
    },
  };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} time slot entity object, which is to be ensured against null values
 */
export const ensureTimeSlot = timeSlot => {
  const empty = { id: null, type: 'timeSlot', attributes: {} };
  return { ...empty, ...timeSlot };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureDayAvailabilityPlan = availabilityPlan => {
  const empty = { type: 'availability-plan/day', entries: [] };
  return { ...empty, ...availabilityPlan };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureAvailabilityException = availabilityException => {
  const empty = { id: null, type: 'availabilityException', attributes: {} };
  return { ...empty, ...availabilityException };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensureStripeCustomer = stripeCustomer => {
  const empty = { id: null, type: 'stripeCustomer', attributes: {} };
  return { ...empty, ...stripeCustomer };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensurePaymentMethodCard = stripePaymentMethod => {
  const empty = {
    id: null,
    type: 'stripePaymentMethod',
    attributes: { type: 'stripe-payment-method/card', card: {} },
  };
  const cardPaymentMethod = { ...empty, ...stripePaymentMethod };

  if (cardPaymentMethod.attributes.type !== 'stripe-payment-method/card') {
    throw new Error(`'ensurePaymentMethodCard' got payment method with wrong type.
      'stripe-payment-method/card' was expected, received ${cardPaymentMethod.attributes.type}`);
  }

  return cardPaymentMethod;
};

/**
 * Get the display name of the given user as string. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned or deleted users, a translated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayNameAsString = (user, defaultUserDisplayName) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName =
    hasProfile && (user.attributes.profile.displayName || user.attributes.profile.abbreviatedName);

  if (hasDisplayName) {
    return hasDisplayName;
  } else {
    return defaultUserDisplayName || '';
  }
};

/**
 * DEPRECATED: Use userDisplayNameAsString function or UserDisplayName component instead
 *
 * @param {propTypes.user} user
 * @param {String} bannedUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayName = (user, bannedUserDisplayName) => {
  console.warn(
    `Function userDisplayName is deprecated!
User function userDisplayNameAsString or component UserDisplayName instead.`
  );

  return userDisplayNameAsString(user, bannedUserDisplayName);
};

/**
 * Get the abbreviated name of the given user. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned  or deleted users, a default abbreviated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserAbbreviatedName
 *
 * @return {String} abbreviated name that can be rendered in the UI
 * (e.g. in Avatar initials)
 */
export const userAbbreviatedName = (user, defaultUserAbbreviatedName) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName = hasProfile && user.attributes.profile.abbreviatedName;

  if (hasDisplayName) {
    return user.attributes.profile.abbreviatedName;
  } else {
    return defaultUserAbbreviatedName || '';
  }
};

/**
 * A customizer function to be used with the
 * mergeWith function from lodash.
 *
 * Works like merge in every way exept that on case of
 * an array the old value is completely overridden with
 * the new value.
 *
 * @param {Object} objValue Value of current field, denoted by key
 * @param {Object} srcValue New value
 * @param {String} key Key of the field currently being merged
 * @param {Object} object Target object that is receiving values from source
 * @param {Object} source Source object that is merged into object param
 * @param {Object} stack Tracks merged values
 *
 * @return {Object} New value for objValue if the original is an array,
 * otherwise undefined is returned, which results in mergeWith using the
 * standard merging function
 */
export const overrideArrays = (objValue, srcValue, key, object, source, stack) => {
  if (isArray(objValue)) {
    return srcValue;
  }
};

/**
 * Humanizes a line item code. Strips the "line-item/" namespace
 * definition from the beginnign, replaces dashes with spaces and
 * capitalizes the first character.
 *
 * @param {string} code a line item code
 *
 * @return {string} returns the line item code humanized
 */
export const humanizeLineItemCode = code => {
  if (!/^line-item\/.+/.test(code)) {
    throw new Error(`Invalid line item code: ${code}`);
  }
  const lowercase = code.replace(/^line-item\//, '').replace(/-/g, ' ');

  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

export const ensurePricing = pricing => {
  const empty = {
    regularDays: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
    peakDays: [],
    peakPrice: null,
    discount: {},
  };

  return { ...empty, ...pricing };
};

export const calculateTotalPrice = (days, regularPrice, peakPrice) => {
  const { regular = 0, peak = 0 } = days;
  const totalPrice =
    regular * convertMoneyToNumber(regularPrice) + convertMoneyToNumber(peakPrice) * peak;

  return new Money(totalPrice * unitDivisor(config.currency), config.currency);
};

const estimatedTotalPrice = (unitPrice, unitCount, refund = false) => {
  const numericPrice = convertMoneyToNumber(unitPrice);
  const numericTotalPrice = new Decimal(numericPrice).times(unitCount).toNumber();
  return new Money(
    convertUnitToSubUnit(
      refund ? -1 * numericTotalPrice : numericTotalPrice,
      unitDivisor(unitPrice.currency)
    ),
    unitPrice.currency
  );
};

const estimatedTotalHourlyPrice = (unitPrice, unitCount, refund = false) => {
  console.log("Getting estimated total", unitPrice, unitCount, refund = false);
  const numericPrice = convertMoneyToNumber(unitPrice);
  const numericTotalPrice = new Decimal(numericPrice).times(unitCount).toNumber();
  return new Money(
    convertUnitToSubUnit(
      refund ? -1 * numericTotalPrice : numericTotalPrice,
      unitDivisor(unitPrice.currency)
    ),
    unitPrice.currency
  );
};

const isOldTransactionProcess = tx => {
  return [
    config.bookingProcessAliasPrivate,
    config.bookingProcessAliasCommercial,
    config.bookingProcessAliasYoungPrivate,
    config.bookingProcessAliasYoungCommercial,
  ].some(bookingProcessAlias => bookingProcessAlias.includes(tx.attributes.processName));
};

const lineItemsForOldTransaction = tx => {
  const { lineItems } = tx.attributes;
  const lineItemCustomerCommission = lineItems.find(
    lineItem => lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION
  );
  if (!lineItemCustomerCommission) return [];
  const commissionConfig = getTxCommissionConfig(tx);
  const isMinimum = commissionConfig.minimum === lineItemCustomerCommission.lineTotal.amount;
  const isYoung =
    config.bookingProcessAliasYoungPrivate.includes(tx.attributes.processName) ||
    config.bookingProcessAliasYoungCommercial.includes(tx.attributes.processName);
  const result = [];
  if (isMinimum) {
    result.push({
      code: LINE_ITEM_PROCESSING_FEE,
      unitPrice: new Money(300, config.currency),
      lineTotal: new Money(300, config.currency),
    });
    if (isYoung) {
      result.push({
        code: LINE_ITEM_YOUNG_CUSTOMER_COMMISSION,
        unitPrice: new Money(800, config.currency),
        lineTotal: new Money(800, config.currency),
      });
      result.push({
        code: LINE_ITEM_CUSTOMER_COMMISSION,
        unitPrice: new Money(lineItemCustomerCommission.lineTotal.amount - 11000, config.currency),
        lineTotal: new Money(lineItemCustomerCommission.lineTotal.amount - 11000, config.currency),
      });
    } else {
      result.push({
        code: LINE_ITEM_CUSTOMER_COMMISSION,
        unitPrice: new Money(lineItemCustomerCommission.lineTotal.amount - 300, config.currency),
        lineTotal: new Money(lineItemCustomerCommission.lineTotal.amount - 300, config.currency),
      });
    }
  } else {
    result.push({
      code: LINE_ITEM_PROCESSING_FEE,
      unitPrice: new Money(
        (lineItemCustomerCommission.lineTotal.amount / commissionConfig.percentage) * 0.04,
        lineItemCustomerCommission.lineTotal.currency
      ),
      lineTotal: new Money(
        (lineItemCustomerCommission.lineTotal.amount / commissionConfig.percentage) * 0.04,
        lineItemCustomerCommission.lineTotal.currency
      ),
    });
    if (isYoung) {
      result.push({
        code: LINE_ITEM_YOUNG_CUSTOMER_COMMISSION,
        unitPrice: new Money(
          (lineItemCustomerCommission.lineTotal.amount / commissionConfig.percentage) * 0.1,
          lineItemCustomerCommission.lineTotal.currency
        ),
        lineTotal: new Money(
          (lineItemCustomerCommission.lineTotal.amount / commissionConfig.percentage) * 0.1,
          lineItemCustomerCommission.lineTotal.currency
        ),
      });
      result.push({
        code: LINE_ITEM_CUSTOMER_COMMISSION,
        unitPrice: new Money(
          (lineItemCustomerCommission.lineTotal.amount / commissionConfig.percentage) *
          (commissionConfig.percentage - 0.1 - 0.04),
          config.currency
        ),
        lineTotal: new Money(
          (lineItemCustomerCommission.lineTotal.amount / commissionConfig.percentage) *
          (commissionConfig.percentage - 0.1 - 0.04),
          config.currency
        ),
      });
    } else {
      result.push({
        code: LINE_ITEM_CUSTOMER_COMMISSION,
        unitPrice: new Money(
          (lineItemCustomerCommission.lineTotal.amount / commissionConfig.percentage) *
          (commissionConfig.percentage - 0.04),
          config.currency
        ),
        lineTotal: new Money(
          (lineItemCustomerCommission.lineTotal.amount / commissionConfig.percentage) *
          (commissionConfig.percentage - 0.04),
          config.currency
        ),
      });
    }
  }

  return result;
};

export const restoreTransaction = (tx, canApplyMastercardPromo) => {
  console.log("HEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE", tx);
  if (!tx || !tx.id) return tx;
  const ensuredTx = ensureTransaction(cloneDeep(tx));
  const { listing, attributes } = ensuredTx;
  const { days =  {}, hours = {}, hourlyBooking, hourDuration, pricing = {}, initialPayment } = attributes.protectedData || {};
  if (isEmpty(days) && isEmpty(hours)) return ensuredTx;
  // from transaction
  const { peakPrice: rawPeakPrice, regularPrice: rawRegularPrice,  hourlyRegularPrice: rawHourlyPrice, hourlyPeakPrice: rawHourlyPeakPrice } = pricing;
  const listingPricing = get(listing, 'attributes.publicData.pricing', {});
  const lRawRegularPrice = get(listing, 'attributes.price');
  //from listing
  const { peakPrice: lRawPeakPrice, hourlyRegularPrice, hourlyPeakPrice  } = listingPricing;
  const { regular, peak } = days;
  const {regular: regularHours, peak: peakHours} = hours ;

  const lineItemUnit = tx.attributes.lineItems.find(lineItem => lineItem.code === LINE_ITEM_UNITS);
  const lineItemUnitRefund = tx.attributes.lineItems.find(
    lineItem => lineItem.code === LINE_ITEM_UNITS && lineItem.reversal === true
  );
  let totalPrice = lineItemUnit.lineTotal.amount;
  let lineItems = attributes.lineItems.filter(lineItem => lineItem.code !== '');

  if ((peak > 0 || peakHours > 0) && (rawPeakPrice || lRawPeakPrice || rawHourlyPeakPrice || hourlyPeakPrice)) {
    const peakPrice = rawPeakPrice
      ? new Money((rawPeakPrice.amount).toFixed(0) * 100, rawPeakPrice.currency)
      : new Money((lRawPeakPrice.amount).toFixed(0), lRawPeakPrice.currency);
   
    if (hourlyBooking ) {
      if(peakHours) {
        const peakHourlyPrice = (rawHourlyPeakPrice && rawHourlyPeakPrice.amount) && (hourlyPeakPrice && hourlyPeakPrice.amount) ?
        rawHourlyPeakPrice && rawHourlyPeakPrice.amount
          ? new Money(rawHourlyPeakPrice.amount * 100, rawHourlyPeakPrice.currency)
          : new Money(hourlyPeakPrice ? hourlyPeakPrice.amount : 0, hourlyPeakPrice ? hourlyPeakPrice.currency : 'AUD'): false;
  
        lineItems = [
          {
            code: LINE_ITEM_TRIP_PRICE_PEAK_HOURS,
            unitPrice: peakHourlyPrice || peakPrice,
            quantity: peakHours || 1,
            lineTotal: peakHours > 0 && peakHourlyPrice ? estimatedTotalHourlyPrice(hourlyPeakPrice || hourlyRegularPrice, peakHours) : lineItemUnit.lineTotal,
            reversal: false,
            isHourlyApplied: true
          },
          ...lineItems,
        ];
        if (lineItemUnitRefund) {
          lineItems.push({
            code: LINE_ITEM_TRIP_PRICE_PEAK_HOURS_REFUND,
            unitPrice: peakHourlyPrice || peakPrice,
            quantity: peakHours || 1,
            lineTotal: peakHours > 0 && peakHourlyPrice ?  estimatedTotalHourlyPrice(hourlyPeakPrice || hourlyRegularPrice, peakHours, true) : {
              ...lineItemUnit.lineTotal,
              amount: -1 * lineItemUnit.lineTotal.amount,
            },
            reversal: true,
            isHourlyApplied: true
          });
        }
      }
    } else {
      if (peak > 0) {
        lineItems = [
          {
            code: LINE_ITEM_TRIP_PRICE_PEAK_DAYS,
            unitPrice: peakPrice,
            quantity: peak,
            lineTotal: regular > 0 ? estimatedTotalPrice(peakPrice, peak) : lineItemUnit.lineTotal,
            reversal: false,
          },
          ...lineItems,
        ];
      }
      if (lineItemUnitRefund) {
        lineItems.push({
          code: LINE_ITEM_TRIP_PRICE_PEAK_DAYS_REFUND,
          unitPrice: peakPrice,
          quantity: peak,
          lineTotal: regular > 0 ? estimatedTotalPrice(peakPrice, peak, true) : {
            ...lineItemUnit.lineTotal,
            amount: -1 * lineItemUnit.lineTotal.amount,
          },
          reversal: true,
        });
      }
    }
  }

  if (regular > 0 || regularHours > 0) {
    const regularPrice =
        rawRegularPrice && rawRegularPrice.amount
          ? new Money(rawRegularPrice.amount * 100, rawRegularPrice.currency)
          : new Money(lRawRegularPrice.amount, lRawRegularPrice.currency);
    if (hourlyBooking ) {
      if(regularHours > 0) {
      const regularHourlyPrice =  (rawHourlyPrice && rawHourlyPrice.amount) || (hourlyRegularPrice && hourlyRegularPrice.amount) ? 
      rawHourlyPrice && rawHourlyPrice.amount
        ? new Money(rawHourlyPrice.amount * 100, rawHourlyPrice.currency)
        : new Money(hourlyRegularPrice ? hourlyRegularPrice.amount : 0, hourlyRegularPrice ? hourlyRegularPrice.currency : 'AUD'): false;

      const peakHoursLineItem = lineItems.find(i => i.code === LINE_ITEM_TRIP_PRICE_PEAK_HOURS);
      console.log("Peak Hourly Booking line item actual", peakHoursLineItem, peakHours);
      const lineTotalAmount = peakHours
        ? lineItemUnit.lineTotal.amount - peakHoursLineItem.lineTotal.amount
        : lineItemUnit.lineTotal.amount
        ;

      lineItems = [
        {
          code: LINE_ITEM_TRIP_PRICE_REGULAR_HOURS,
          unitPrice: regularHourlyPrice || regularPrice,
          quantity: regularHours || 1,
          lineTotal: { ...lineItemUnit.lineTotal, amount: lineTotalAmount },
          reversal: !!(lineItemUnit && lineItemUnit.reversal),
          isHourlyApplied: true
        },
        ...lineItems,
      ];

      if (lineItemUnitRefund) {
        lineItems.push({
          code: LINE_ITEM_TRIP_PRICE_REGULAR_HOURS_REFUND,
          unitPrice:  regularHourlyPrice || regularPrice,
          quantity: regularHours || 1,
          lineTotal: { ...lineItemUnit.lineTotal, amount: -1 * lineTotalAmount },
          reversal: false,
          isHourlyApplied: true
        });
      }
    }
      
    } else {
      const regularPrice =
        rawRegularPrice && rawRegularPrice.amount
          ? new Money(rawRegularPrice.amount * 100, rawRegularPrice.currency)
          : new Money(lRawRegularPrice.amount, lRawRegularPrice.currency);

      const peakDaysLineItem = lineItems.find(i => i.code === LINE_ITEM_TRIP_PRICE_PEAK_DAYS);
      const lineTotalAmount = peak
        ? lineItemUnit.lineTotal.amount - peakDaysLineItem.lineTotal.amount
        : lineItemUnit.lineTotal.amount
        ;
      lineItems = [
        {
          code: LINE_ITEM_TRIP_PRICE_REGULAR_DAYS,
          unitPrice: regularPrice || lRawRegularPrice,
          quantity: regular,
          lineTotal: { ...lineItemUnit.lineTotal, amount: lineTotalAmount },
          reversal: !!(lineItemUnit && lineItemUnit.reversal),
        },
        ...lineItems,
      ];
      if (lineItemUnitRefund) {
        lineItems.push({
          code: LINE_ITEM_TRIP_PRICE_REGULAR_DAYS_REFUND,
          unitPrice: regularPrice || lRawRegularPrice,
          quantity: regular,
          lineTotal: { ...lineItemUnit.lineTotal, amount: -1 * lineTotalAmount },
          reversal: false,
        });
      }
    }
  }

  const promoLineItem = attributes.lineItems.find(
    lineItem => lineItem.code === LINE_ITEM_CUSTOMER_PROMO
  );

  if (canApplyMastercardPromo && !promoLineItem) {
    const { currency } = listing.attributes.price;
    const mastercardPromoAmount = new Money(Math.max(-totalPrice / 2, -5000), currency);
    lineItems = [
      {
        code: LINE_ITEM_MASTERCARD_PROMO,
        unitPrice: new Money(Math.min(totalPrice, 10000), currency),
        percentage: -50,
        lineTotal: mastercardPromoAmount,
      },
      ...lineItems,
    ];
    ensuredTx.attributes.payinTotal.amount =
      ensuredTx.attributes.payinTotal.amount + mastercardPromoAmount.amount;
    ensuredTx.attributes.payoutTotal.amount =
      ensuredTx.attributes.payoutTotal.amount + mastercardPromoAmount.amount;
  }

  if (isOldTransactionProcess(tx)) {
    lineItems = lineItems.filter(lineItem => lineItem.code !== LINE_ITEM_CUSTOMER_COMMISSION);
    lineItems = [...lineItems, ...lineItemsForOldTransaction(tx)];
  }

  // const total = lineItems.length && lineItems.reduce((acc, curr) => {
  //   if (!curr.includeFor || curr.includeFor.includes('customer')) {
  //     acc += curr.lineTotal.amount
  //   }
  //   return acc;
  // }, 0);

  initialPayment && lineItems.push({
    code: LINE_ITEM_INITIAL_PAYMENT,
    unitPrice: new Money(-initialPayment, 'AUD'),
    lineTotal: new Money(-initialPayment, 'AUD'),
    percentage: -100,
    reversal: false,
    includeFor: ["customer"],
  })

  ensuredTx.attributes.lineItems = lineItems;

  return ensuredTx;
};

export const getListingPricing = listing => {
  const pricing = get(listing, 'attributes.publicData.pricing', {});
  return ensurePricing(pricing);
};

export const getTxCommissionConfig = tx => {
  const processName = tx.attributes.processName;
  if (config.bookingProcessAliasPrivate.includes(processName) || txIsNormalPrivate(tx)) {
    return {
      percentage: 0.29,
      minimum: 2000,
    };
  }
  if (config.bookingProcessAliasCommercial.includes(processName) || txIsNormalCommercial(tx)) {
    return {
      percentage: 0.215,
      minimum: 1300,
    };
  }
  if (config.bookingProcessAliasYoungPrivate.includes(processName) || txIsYoungPrivate(tx)) {
    return {
      percentage: 0.39,
      minimum: 2800,
    };
  }
  if (config.bookingProcessAliasYoungCommercial.includes(processName) || txIsYoungCommercial(tx)) {
    return {
      percentage: 0.315,
      minimum: 2100,
    };
  }
};

export const checkOwnListing = (currentUser, currentListing) => {
  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
  return isOwnListing;
};

export const listingIsCommercial = listing => {
  const ensuredListing = ensureListing(listing);
  if (!ensuredListing.id) return false;
  return ensuredListing.attributes.publicData.insurance === 'commercial';
};

export const listingIsInstantBooking = listing => {
  const ensuredListing = ensureListing(listing);
  if (!ensuredListing.id) return false;
  return listing.attributes.publicData.instantBooking;
};

export const currentUserIsYoungDriver = user => {
  const ensuredUser = ensureCurrentUser(user);
  if (!ensuredUser.id) return false;

  const { protectedData = {} } = ensuredUser.attributes.profile;
  const { dateOfBirth = {} } = protectedData;
  const { year } = dateOfBirth;
  const currentYear = new Date().getFullYear();
  return year && currentYear - year <= 24;
};

export const currentUserIdentityStatus = currentUser => {
  const ensuredUser = ensureCurrentUser(currentUser);
  if (!ensuredUser.id) return {};
  return ensuredUser.identityStatus;
};

export const currentUserCanRequestToBooking = currentUser => {
  const ensuredUser = ensureCurrentUser(currentUser);
  if (!ensuredUser.id) return false;
  const { privateData } = ensuredUser.attributes.profile;
  return currentUser.canRequestBooking || privateData.canRequestBooking;
};

export const getBookingConfig = listing => {
  if (!listing) {
    return {
      type: bookingTypes.DAILY,
      minimum: 1,
    };
  }

  const {
    hourlyBooking = false,
    minimumHourlyDuration = 2,
    minimumDailyDuration = 1,
    paddingHours,
  } = listing.attributes.publicData || {};

  if (hourlyBooking) {
    return {
      type: bookingTypes.HOURLY,
      minimum: minimumHourlyDuration,
      paddingHours,
    };
  }

  return {
    type: bookingTypes.DAILY,
    minimum: minimumDailyDuration,
    paddingHours,
  };
};

export const listingIsDrivelahGo = listing => {
  return get(listing, 'attributes.metadata.isDrivelahGo', false);
};

export const isChargeDeposit = tx => {
  const shouldChargeDeposit = get(tx, 'attributes.protectedData.shouldChargeDeposit', false);

  if (!shouldChargeDeposit) {
    return false;
  }
  return true;
};

export const listingPriceAsString = (listing, intl) => {
  const price = get(listing, 'attributes.price', null);
  const longTermRental = get(listing, 'attributes.publicData.longTermRental', false);
  if (!price) return null;
  const priceMaybe = longTermRental
    ? new Money(new Decimal(convertMoneyToNumber(price) * 100).times(30).toNumber(), price.currency)
    : price;
  const unitTranslationKey = longTermRental ? 'ListingPrice.monthly' : 'ListingPrice.daily';
  const formattedMoney = formatMoney(intl, priceMaybe);
  return `${formattedMoney}${intl.formatMessage({ id: unitTranslationKey })}`;
};

export const listingIsLongTermRental = listing =>
  get(listing, 'attributes.publicData.longTermRental', false);

export const getListingMonthlyPrice = listing => {
  const longTermPrice = get(listing, 'attributes.publicData.longTermPrice', {
    amount: 0,
    currency: config.currency,
  });

  const isPrivateCar = get(listing, 'attributes.publicData.insurance', 'private') === 'private';
  const feeMaybe = isPrivateCar ? 350 * 100 : 250 * 100;

  return new Money((longTermPrice.amount + feeMaybe) * 1.04, config.currency);
};

export const getListingMonthlyFlexPrice = listing => {
  const longTermPrice = parseFloat(get(listing, 'attributes.publicData.flexPlusPrice', 0));

  return new Money(longTermPrice, config.currency);
};
