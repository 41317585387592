/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import Decimal from 'decimal.js';
import isEqual from 'lodash/isEqual';
import { types as sdkTypes } from '../../util/sdkLoader';
import React, { memo } from 'react';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import classNames from "classnames";
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import {
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_INITIAL_PAYMENT_PROVIDER,
  LINE_ITEM_TRIP_PRICE_PEAK_DAYS,
  LINE_ITEM_TRIP_PRICE_PEAK_HOURS,
  LINE_ITEM_TRIP_PRICE_REGULAR_DAYS,
  LINE_ITEM_TRIP_PRICE_REGULAR_HOURS,
  LINE_ITEMS,
  propTypes,
} from '../../util/types';
import css from './BookingBreakdown.css';
import Explanation from './Explaination';

const { Money } = sdkTypes;

function getUnique(arr, comp) {
  // store the comparison  values in array
  const unique = arr
    .map(e => e[comp])

    // store the indexes of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the false indexes & return unique objects
    .filter(e => arr[e])
    .map(e => arr[e]);

  return unique;
}

const LineItemUpdateBookingPeriod = memo(
  props => {
    const { transaction, intl, isProvider, isCustomer, isEditTripPage, isExplanationBreakdown } = props;

    const initialPaymentLineItem =
      transaction &&
      transaction.attributes.lineItems.find(
        item => item.code === LINE_ITEM_INITIAL_PAYMENT && !item.reversal
      );

    // resolve unknown non-reversal line items
    const rawItems =
      transaction &&
      transaction.attributes.lineItems.filter(
        item =>
          initialPaymentLineItem &&
          isEditTripPage &&
          [LINE_ITEM_INITIAL_PAYMENT, LINE_ITEM_INITIAL_PAYMENT_PROVIDER, ...LINE_ITEMS].indexOf(
            item.code
          ) === -1 &&
          !item.reversal
      );

    const items = getUnique(rawItems, 'code');

    if (!items) return null;

    const { hourlyBooking } = transaction.attributes.protectedData || {};

    const unitMaybe = hourlyBooking ? 'hours' : 'days';
    return items.length > 0 ? (
      <React.Fragment>
        {items.map((item, i) => {
          const unitPrice = item.unitPrice;

          const type = [LINE_ITEM_TRIP_PRICE_REGULAR_DAYS, LINE_ITEM_TRIP_PRICE_REGULAR_HOURS].includes(item.code)
            ? 'regular'
            : [LINE_ITEM_TRIP_PRICE_PEAK_DAYS, LINE_ITEM_TRIP_PRICE_PEAK_HOURS].includes(item.code)
              ? 'peak'
              :  '';
          // const label = humanizeLineItemCode(item.code);
          const formattedTotal = formatMoney(intl, item.lineTotal, 1);
          const hasQuantity = !!item.quantity;
          const unitQuantity = hasQuantity
            ? item.quantity instanceof Decimal
              ? item.quantity.toString()
              : item.quantity
            : 0;
          const explainationMaybe =
            item.code === LINE_ITEM_TRIP_PRICE_PEAK_DAYS ? (
              <Explanation text="Peak day pricing is for select high demand days set by the host." />
            ) : item.code === LINE_ITEM_TRIP_PRICE_PEAK_HOURS ? (
              <Explanation text="Peak hour pricing is for select high demand hours set by the host." />
            ) : null;
          return (
            <div key={`${i}-item.code`} className={css.lineItem}>
              <span className={css.itemLabel}>
                {hasQuantity ? (
                  // <FormattedMessage
                  //   id={`LineItemUnknownItemsMaybe.${unitMaybe}.quantity`}
                  //   values={{
                  //     unitPrice: formatMoney(intl, item.unitPrice, 1),
                  //     quantity: unitQuantity,
                  //   }}
                  // />
                  <FormattedMessage
                    id={`LineItemUpdateBookingPeriod.${unitMaybe}.quantity`}
                    values={{
                      unitPrice: formatMoney(intl, unitPrice, 1),
                      quantity: unitQuantity,
                      type
                    }}
                  />
                ) : null}
                {' '}
                {explainationMaybe}{' '}
              </span>

              <span className={classNames(css.itemValue, {[css.explanationValue]: isExplanationBreakdown})}>{formattedTotal}</span>
            </div>
          );
        })}
      </React.Fragment>
    ) : null;
  },
  (prev, next) => isEqual(prev.transaction, next.transaction)
);

LineItemUpdateBookingPeriod.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUpdateBookingPeriod;
