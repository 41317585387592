import React from 'react';
import { get } from 'lodash';
import moment from 'moment-timezone';

import css from './TripModificationPeriod.css';

const IconArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Icon_feather-arrow-left"
      data-name="Icon feather-arrow-left"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Path_1499"
        data-name="Path 1499"
        d="M-5518.373,2965.932l7.016-7.016a.961.961,0,0,0,.3-.7.2.2,0,0,0,0-.024.961.961,0,0,0-.312-.686l-7.007-7.008a.96.96,0,0,0-1.36,0,.96.96,0,0,0,0,1.36l5.394,5.395h-11.751a.962.962,0,0,0-.962.963.963.963,0,0,0,.962.963h11.751l-5.394,5.393a.962.962,0,0,0,0,1.362.957.957,0,0,0,.68.283A.956.956,0,0,0-5518.373,2965.932Z"
        transform="translate(5527.052 -2950.215)"
        fill="#4a4a4a"
      />
    </svg>
  );
};

const TripModificationPeriod = ({ transaction }) => {
  if (!transaction) return null;

  const tz =
    (transaction &&
      transaction.listing &&
      transaction.listing.attributes.publicData.listingTimezone) ||
    'Australia/Sydney';

  const { bookingDisplayStart, bookingDisplayEnd } = get(
    transaction,
    'attributes.protectedData.initialTx.attributes.protectedData',
    {}
  );

  const originalFormattedStart =
    bookingDisplayStart &&
    moment(bookingDisplayStart)
      .tz(tz)
      .format('D MMM h:mmA');
  const originalFormattedEnd =
    bookingDisplayEnd &&
    moment(bookingDisplayEnd)
      .tz(tz)
      .format('D MMM h:mmA');

  const { updateBookingStartTimestamp, updateBookingEndTimestamp } = get(
    transaction,
    'attributes.protectedData',
    {}
  );

  const updatedFormattedStart =
    updateBookingStartTimestamp &&
    moment(updateBookingStartTimestamp)
      .tz(tz)
      .format('D MMM h:mmA');
  const updatedFormattedEnd =
    updateBookingEndTimestamp &&
    moment(updateBookingEndTimestamp)
      .tz(tz)
      .format('D MMM h:mmA');

  return (
    <div className={css.modificationDatesRoot}>
      <span className={css.head}>Updated trip</span>
      <div className={css.datesContainer}>
        <div className={css.item}>
          <span className={css.original}>
            {originalFormattedStart}
            <br />
            to {originalFormattedEnd}
          </span>
        </div>
        <div className={css.item}>
          <IconArrow />
        </div>
        <div className={css.item}>
          <span className={css.updated}>
            {updatedFormattedStart}
            <br />
            to {updatedFormattedEnd}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TripModificationPeriod;
