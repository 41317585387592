import get from 'lodash/get';
import React, { useState } from 'react';
import { obfuscatedCoordinates } from '../../util/maps';
import { types as sdkTypes } from '../../util/sdkLoader';
import Map from '../Map/Map';
import classNames from 'classnames';
import css from './TripPanel.css';

const { LatLng } = sdkTypes;

const ListingMap = ({ listing, className, showAddress, geolocation, address, showExactShuLocation }) => {


  console.log("geolocation", geolocation);
  

  const [isStatic, setStatic] = useState(true);

  if (!geolocation) {
    return null;
  }

  // const address = get(listing, 'attributes.publicData.location.address', '');
  // geolocation = get(listing, 'attributes.geolocation', { lat: 0, lng: 0 });
  const defaultAddress = get(listing, 'attributes.publicData.location.address', '');
  const partialAddress = defaultAddress ? defaultAddress?.split(',')[1] : "";
  const { lat = 0, lng = 0 } = geolocation || {};
  const cacheKey = listing.id ? `${listing.id.uuid}_${lat}_${lng}` : null;
  const hideAddress = !showExactShuLocation;

  const mapProps = hideAddress
    ? {
        address: partialAddress,
        obfuscatedCenter: obfuscatedCoordinates({ lat, lng }, cacheKey),
        hideAddress,
        showMarker: false, // Marker is not shown when address is hidden
      }
    : {
        address: address?.address ?? "",
        center: new LatLng(lat, lng),
        hideAddress,
      };

  const map = <Map {...mapProps} useStaticMap={isStatic} hideAddress={hideAddress} />;


  if(showAddress){
    return (
      <div>
        {isStatic ? (
          <button
            className={classNames(css.map, className)}
            onClick={() => {
              if(showExactShuLocation == true){
                setStatic(false);
              }
            }}
          >
            {map}
          </button>
        ) : (
          <div className={classNames(css.map, className)}>{map}</div>
        )}
      </div>
    );
  }else{
    return <></>
  }

  
};

export default ListingMap;
