import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';
import { formatMoney } from '../../util/currency';
import classNames from 'classnames';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import {
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_INITIAL_PAYMENT_PROVIDER,
  LINE_ITEM_TRIP_PRICE_PEAK_DAYS,
  LINE_ITEM_TRIP_PRICE_PEAK_HOURS,
  LINE_ITEM_TRIP_PRICE_REGULAR_DAYS,
  LINE_ITEM_TRIP_PRICE_REGULAR_HOURS,
  LINE_ITEM_UNITS, LINE_ITEMS,
  propTypes,
} from '../../util/types';
import Explanation from './Explaination';
import { humanizeLineItemCode } from '../../util/data';
import Decimal from 'decimal.js';
import css from './BookingBreakdown.css';

function getUnique(arr, comp) {
  // store the comparison  values in array
  return arr
    .map(e => e[comp])

    // store the indexes of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the false indexes & return unique objects
    .filter(e => arr[e])
    .map(e => arr[e]);

}

const LineItemPreviousBookingPeriod = memo(
  props => {
    const { transaction, intl, isEditTripPage, isExplanationBreakdown } = props;

    if (!transaction || !isEditTripPage) return null;

    const { hourlyBooking, hourDuration, days, pricing } =
      transaction.attributes.protectedData || {};

    // const unitLineItem =
    //   transaction &&
    //   transaction.attributes.lineItems.find(
    //     item => item.code === LINE_ITEM_UNITS && !item.reversal
    //   );

    const rawItems =
      transaction &&
      transaction.attributes.lineItems.filter(
        item =>
          [LINE_ITEM_TRIP_PRICE_REGULAR_DAYS, LINE_ITEM_TRIP_PRICE_PEAK_DAYS, LINE_ITEM_TRIP_PRICE_REGULAR_HOURS, LINE_ITEM_TRIP_PRICE_PEAK_HOURS].includes(item.code) &&
          !item.reversal
      );

    const unitMaybe = hourlyBooking ? 'hours' : 'days';

    const items = getUnique(rawItems, 'code');

    // const regular = () => {
    //   const label = <FormattedMessage id="LineItemRegularPrice.label" />;
    //   const hasQuantity = days.regular > 0;
    //   const unitQuantity = hourlyBooking ? hourDuration : days.regular;
    //   const price = unitLineItem.lineTotal.amount / unitQuantity;
    //   const currency = pricing.regularPrice.currency;
    //   // const formattedTotal = formatMoney(intl, { amount: price * unitQuantity, currency }, 1);
    //   const formattedTotal = formatMoney(intl, unitLineItem.lineTotal, 1);
    //   return (
    //     <div className={css.lineItem}>
    //       <span className={css.itemLabel}>
    //         {hasQuantity ? (
    //           <FormattedMessage
    //             id={`LineItemUnknownItemsMaybeNew.${unitMaybe}.quantity`}
    //             values={{
    //               unitPrice: formatMoney(intl, { amount: price, currency }, 1),
    //               quantity: unitQuantity,
    //             }}
    //           />
    //         ) : null}{' '}
    //         {label}
    //       </span>
    //       <span
    //         className={classNames(css.itemValue, {
    //           [css.explanationValue]: isExplanationBreakdown,
    //         })}
    //       >
    //         {formattedTotal}
    //       </span>
    //     </div>
    //   );
    // };

    // const peak = () => {
    //   const label = <FormattedMessage id="LineItemPeakPrice.label" />;
    //   const hasQuantity = days.peak > 0;
    //   const unitQuantity = hourlyBooking ? hourDuration : days.peak;
    //   const price = unitLineItem.lineTotal.amount / unitQuantity;
    //   const currency = pricing.peakPrice.currency;
    //   // const formattedTotal = formatMoney(
    //   //   intl,
    //   //   { ...pricing.peakPrice, amount: price * unitQuantity },
    //   //   1
    //   // );
    //   const formattedTotal = formatMoney(intl, unitLineItem.lineTotal, 1);
    //
    //   const explanationMaybe = days.peak ? (
    //     <Explanation text="Peak day pricing is for select high demand days set by the host." />
    //   ) : null;
    //   return (
    //     <div className={css.lineItem}>
    //       <span className={css.itemLabel}>
    //         {hasQuantity ? (
    //           <FormattedMessage
    //             id={`LineItemUnknownItemsMaybeNew.${unitMaybe}.quantity`}
    //             values={{
    //               unitPrice: formatMoney(intl, { amount: price, currency }, 1),
    //               quantity: unitQuantity,
    //             }}
    //           />
    //         ) : null}{' '}
    //         {label}
    //         {explanationMaybe}{' '}
    //       </span>
    //       <span
    //         className={classNames(css.itemValue, {
    //           [css.explanationValue]: isExplanationBreakdown,
    //         })}
    //       >
    //         {formattedTotal}
    //       </span>
    //     </div>
    //   );
    // };

    return items.length > 0 ? (
      <React.Fragment>
        {items.map((item, i) => {
          const unitPrice = item.unitPrice;

          const label = [LINE_ITEM_TRIP_PRICE_REGULAR_DAYS, LINE_ITEM_TRIP_PRICE_REGULAR_HOURS].includes(item.code)
            ? <FormattedMessage id="LineItemRegularPrice.label" />
            : [LINE_ITEM_TRIP_PRICE_PEAK_DAYS, LINE_ITEM_TRIP_PRICE_PEAK_HOURS].includes(item.code)
              ? <FormattedMessage id="LineItemPeakPrice.label" />
              :  humanizeLineItemCode(item.code);
          // const label = humanizeLineItemCode(item.code);
          const formattedTotal = formatMoney(intl, item.lineTotal, 1);
          const hasQuantity = !!item.quantity;
          const unitQuantity = hasQuantity
            ? item.quantity instanceof Decimal
              ? item.quantity.toString()
              : item.quantity
            : 0;
          const explainationMaybe =
            item.code === LINE_ITEM_TRIP_PRICE_PEAK_DAYS ? (
              <Explanation text="Peak day pricing is for select high demand days set by the host." />
            ) : item.code === LINE_ITEM_TRIP_PRICE_PEAK_HOURS ? (
              <Explanation text="Peak hour pricing is for select high demand hours set by the host." />
            ) : null;
          return (
            <div key={`${i}-item.code`} className={css.lineItem}>
              <span className={css.itemLabel}>
                <FormattedMessage id="LineItemPeakPrice.tripPriceLabel" />
                {' '}
                {hasQuantity ? (
                  // <FormattedMessage
                  //   id={`LineItemUnknownItemsMaybe.${unitMaybe}.quantity`}
                  //   values={{
                  //     unitPrice: formatMoney(intl, item.unitPrice, 1),
                  //     quantity: unitQuantity,
                  //   }}
                  // />
                  <FormattedMessage
                    id={`LineItemUnknownItemsMaybeNew.${unitMaybe}.quantity`}
                    values={{
                      unitPrice: formatMoney(intl, unitPrice, 1),
                      quantity: unitQuantity,
                    }}
                  />
                ) : null}
                {' '}
                {label}
                {explainationMaybe}{' '}
              </span>

              <span className={classNames(css.itemValue, {[css.explanationValue]: isExplanationBreakdown})}>{formattedTotal}</span>
            </div>
          );
        })}
      </React.Fragment>
    ) : null
  },
  (prev, next) => isEqual(prev.transaction, next.transaction)
);

LineItemPreviousBookingPeriod.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemPreviousBookingPeriod;
